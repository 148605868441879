<template>
  <div class="writeBlog">
    <div>
      <h1 style="text-align: center; font-size: 30px;">文章编辑</h1>
      <a-button type="primary" @click="save">保存</a-button>
    </div>
    <div class="titleArea">
      <a-row>
        <a-col :span="12">标题(必填): <a-input size="large" placeholder="文章标题" v-model:value = "title" style="width: 400px;" /></a-col>
        <a-col :span="12"><span style="font-size: 16px;">标签(请选择一个):</span> 
          <a-select
            mode="multiple"
            style="width: 300px"
            placeholder="请选择1至3个"
            :filterOption = "filterOption"
            @change="tagSelect"
            :maxTagCount='3'
            v-model:value="selectTags"
          >
            <a-select-option v-for="(item, index) in tags" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row style="margin-top: 16px;">
        <a-col :span="12">
          <span style="line-height: 73px;">简述(必填):</span>
          <a-textarea v-model:value="desc" placeholder="请输入文章简述(不抄100字)"
          :auto-size="{ minRows: 3, maxRows: 6 }"
          style="width: 400px; margin-left: 16px;"
        />
        </a-col>
        <a-col :span="12" style="height: 140px; display: flex;">
          <div class="uploadBtn">
            <a-upload
            :multiple="false"
            accept="image/jpg,image/jpeg,image/png"
            :file-list="fileList"
            list-type="picture-card"
            @preview="handlePreview"
            @change="fileChange"
            style="margin-left: 20px;"
            @remove="handleRemove"
          >
            <div v-if="fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">
                点击此处上传封面图
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="content" id="editor">
      <!-- <ckeditor 
        :editor="editor" 
        v-model="editorData" 
        :config="editorConfig"
        @ready = "onReady"
      >
      </ckeditor> -->
    </div>
  </div>
</template>
<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import EditorUploader from '../../utils/EditorUploader';
  import * as TG from '../../api/tags.js';
  import * as BLOG from '../../api/blog.js';
  import { isLogin } from '../../utils/account.js';
  import { PlusOutlined  } from '@ant-design/icons-vue';
  import { markRaw } from 'vue';
  export default{
    name: 'WriteBlog',
    components: {
      PlusOutlined
    },
    data() {
      return {
        // editor: ClassicEditor,
        editor: null,
        editorData: '在此处编辑文章',
        title: '',
        desc: '',
        tags:[],
        fileList: [],
        previewVisible: false,
        previewImage: '',
        selectTags: [],
      }
    },
    // created() {
    //   window.addEventListener('beforeunload',e => this.watchUserReloadPage(e))
    // },
    // beforeDestroy() {
    //   window.addEventListener('beforeunload',e => this.watchUserReloadPage(e))
    // },
    mounted() {
      this.loadTags();
      this.initEditor();
    },
    methods: {
      save() {
        // console.log(this.editorData)
        if(!isLogin()){
          this.$message.warning('请先登陆再编辑博客')
          this.$router.push({
            path: '/login',
            query: {redirect: this.$router.currentRoute.fullPath}
          })
          return
        }
        if(this.title === '') {
          this.$message.destroy();
          this.$message.warning('请输入文章标题')
          return
        }
        if(this.selectTags.length < 1) {
          this.$message.destroy();
          this.$message.warning('请至少选择一个标签')
          return
        }
        if(this.desc === '') {
          this.$message.destroy();
          this.$message.warning('请输入文章简述')
          return
        }
        // console.log('正文', this.editor.getData())
        if(this.editor.getData() === '' || this.editor.getData() === '在此处编辑文章') {
          this.$message.destroy();
          this.$message.warning('请输入文章正文')
          return
        }
        let params = new FormData()
        params.append('title', this.title)
        params.append('desc', this.desc)
        params.append('content', this.editor.getData())
        let tids = this.selectTags.map(item=>{
          return this.tags[item].id
        })
        // console.log('tids', tids)
        // console.log('file', this.fileList)
        params.append('tagsIds', tids)
        if(this.fileList.length > 0) {
          params.append('cover', this.fileList[0].originFileObj)
        }
        BLOG.insertNewBlog(params).then(res=>{
          console.log('res', res)
          if(res.status === 200 && res.data.code === 200) {
            this.$router.push({
              path: '/blog',
              query: {
                id: res.data.data
              }
            })
          }
        })
      }, 
      tagSelect() {
        // console.log('tags: ', values)
        if(this.selectTags.length > 3) {
          this.$message.warning('最多选择三个标签')
          this.selectTags =  this.selectTags.slice(0,3)
          return
        }
        // console.log('selected ', this.selectTags)
      },
      filterOption(input, option) {
        // console.log('option', option)
        return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase())>=0);
      },
      loadTags() {
        TG.loadAllTags().then(res=>{
          // console.log('all tags', res)
          if(res.data.code === 200) {
            this.tags = res.data.data;
          }
        })
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await this.getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },
      fileChange({ fileList }) {
        this.fileList = fileList;
      },
      beforeUpload(file) {
        // console.log('file check', file)
        const isLt2M =  file.size/1024/1024 < 2
        if(!isLt2M){
          this.$message.error(file.name+'文件大小超出限制，请修改后重新上传')
          return false
        }
        return true
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      watchUserReloadPage(e) {
        e = e || window.event;
          // 兼容IE8和Firefox 4之前的版本
          if (e) {
            e.returnValue = '关闭提示';
          }
          // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
          return '关闭提示';
      },
      initEditor() {
        // console.log(document.querySelector("#toolbar-container"))
        ClassicEditor.create(document.querySelector("#editor"), {
          // Run the editor with the German UI.
          language: 'zh-cn',
          fontSize: { options: [8,9,10,11,12,'default', 14,16,18,20,22,24,26,28,36,44,48,72] },
          toolbar: {
            items: [
              'heading','|','bold', 'link','bulletedList','numberedList','imageUpload','blockQuote',
              'insertTable','mediaEmbed','undo','redo','alignment','fontSize','fontColor', 'codeBlock', 'code',
              'fontFamily', 'highlight'
            ]
          },
          // toolbar: ['fontSize','fontColor', 'codeBlock', 'code'],
          codeBlock: {
            languages: [
              { language: 'java', label: 'Java' },
              { language: 'css', label: 'CSS' },
              { language: 'html', label: 'HTML' },
              { language: 'c', label: 'C' },
              { language: 'c++', label: 'C++' },
              { language: 'javascript', label: 'JavaScript' },
              { language: 'python', label: 'Python' },
              { language: 'go', label: 'Go' },
              { language: 'bash', label: 'Bash' }
            ]
          },
        }).then(editor=> {
          editor
            .plugins
            .get("FileRepository")
            .createUploadAdapter = loader=> new EditorUploader(loader);
            editor.disableReadOnlyMode('editor');
            editor.setData(this.editorData)
            // console.log(document.querySelector("#toolbar-container"))
            // const toolbarContainer = document.querySelector('#toolbar-container');
            // toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            this.editor = markRaw(editor)//将编每器保存起来，用来魔时获取编辑器中的内容等，执行一些操作
            // console.log('editor',editor)
          }
        ).catch(error=>{
          console.log(error)
        })
      }
    },
  }
</script>

<style scoped>
  .writeBlog {
    width: 70%;
    height: 100%;
    min-height: 700px;
    margin: auto;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
  }
  .content {
    margin-top: 12px;
    position: relative;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 3em;
    overflow-y: scroll;
    height: calc(100% - 40px);
  }
  .titleArea {
    margin-top: 20px;
  }
  .uploadBtn {
    width: 400px;
  }
</style>